
          @import "./src/styles/_index.scss";
        
@import 'colors';

// CSS vars export for _colors.ts.
:export {
  transparent: $colorTransparent;
  violetDarkest: $colorVioletDarkest;
  violetDarker: $colorVioletDarker;
  violetDark: $colorVioletDark;
  violetBase: $colorVioletBase;
  violetLight: $colorVioletLight;
  violetLighter: $colorVioletLighter;
  violetLightest: $colorVioletLightest;
  orangeBase: $colorOrangeBase;
  orangeLightest: $colorOrangeLightest;
  navyDarkest: $colorNavyDarkest;
  grayDarker: $colorGrayDarker;
  grayDark: $colorGrayDark;
  grayBase: $colorGrayBase;
  grayLight: $colorGrayLight;
  grayLighter: $colorGrayLighter;
  grayLightest: $colorGrayLightest;
  white: $colorWhite;
  blackWarm: $colorBlackWarm;
  charcoalDarker: $colorCharcoalDarker;
  charcoalBase: $colorCharcoalBase;
  charcoalLight: $colorCharcoalLight;
  yellowBase: $colorYellowBase;
  yellowLighter: $colorYellowLighter;
  yellowLightest: $colorYellowLightest;
  greenBase: $colorGreenBase;
  greenLight: $colorGreenLight;
  greenLightest: $colorGreenLightest;
  redDarkest: $colorRedDarkest;
  redDark: $colorRedDark;
  redBase: $colorRedBase;
  redLighter: $colorRedLighter;
  redLightest: $colorRedLightest;
  blueBase: $colorBlueBase;
  blueLightest: $colorBlueLightest;
}
