
          @import "./src/styles/_index.scss";
        
.root {
  display: flex;
}

.indicatorColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: spacing(4);
}

.indicator {
  @include flexCenter;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 0.1rem solid transparent;
  flex-grow: 0;
  flex-shrink: 0;

  &--completed {
    color: $colorGreenLight;
    border-color: $colorGreenLight;
  }

  &--failed {
    color: $colorRedBase;
    border-color: $colorRedBase;
  }

  &--empty {
    color: $colorGrayDarker;
    border-color: $colorGrayDarker;
  }

  &--stopped {
    color: $colorCharcoalBase;
    border-color: $colorCharcoalBase;
  }
}

.progressLine {
  flex-grow: 1;
  width: 0.1rem;
  border-left: 0.1rem solid $colorGrayDarker;

  &--none {
    display: none;
  }

  &--dashed {
    border-left-style: dashed;
  }
}

.contentColumn {
  flex-grow: 1;
  padding-bottom: spacing(9.5);
}

.statusName--empty {
  color: $colorCharcoalBase;
}
