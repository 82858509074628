
          @import "./src/styles/_index.scss";
        
.root {
  border-bottom: 0.1rem solid $colorGrayDarker;
  z-index: $zindexTopbar;
  background-color: $colorWhite;

  &.isFixed {
    position: fixed;
    top: 0;
    width: calc(100% - $sidebarExpandedWidth);

    &.isSidebarCollapsed {
      width: calc(100% - $sidebarCollapsedWidth);
    }
  }
}

.topBar {
  @include container;
  display: flex;
  align-items: center;
  padding: spacing(3.75, 6);
}

.headerLogo {
  display: block;
  line-height: 0;

  .logoFull {
    @media #{$phone-only} {
      display: none;
    }
  }
  .logoSign {
    @media #{$tablet-up} {
      display: none;
    }
  }
}

.navItems {
  display: flex;
  align-items: center;
  margin-left: auto;
}
