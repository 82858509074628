
          @import "./src/styles/_index.scss";
        
.root {
  @include typoBodyCopySmall;
  border-radius: $radiusInfinite;
  padding: spacing(1, 3);
  display: inline-flex;
  align-items: center;
}

.color-- {
  &green {
    color: $colorGreenLight;
    background-color: $colorGreenLightest;
  }
  &red {
    color: $colorRedBase;
    background-color: $colorRedLightest;
  }
  &yellow {
    color: $colorYellowBase;
    background-color: $colorYellowLighter;
  }
  &orange {
    color: $colorWhite;
    background-color: $colorOrangeBase;
  }
  &gray {
    background-color: $colorCharcoalLight;
  }
  &grayLight {
    background-color: $colorGrayLight;
  }
}
