
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
}

.topMost {
  z-index: 1999;
  position: relative;
}

.swap {
  width: 4rem;
  height: 4rem;
  background-color: $colorGrayLight;
  border-radius: $radiusInfinite;
  &:disabled {
    opacity: 0.5;
  }
}

.balance {
  position: absolute;
  right: 0;
  transform: translateY(-#{spacing(3)});
}
