
          @import "./src/styles/_index.scss";
        
.root {
  padding: spacing(6, 8);
  &:not(:first-of-type) {
    border-top: 0.1rem solid $colorGrayDarker;
  }

  &.variant-- {
    &noBottomPadding {
      padding-bottom: 0;
    }
    &noTopPadding {
      padding-top: 0;
    }
    &noVerticalPadding {
      padding-bottom: 0;
      padding-top: 0;
    }
    &footerButtons,
    &footerButtonsWithoutTopBorder {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > * {
        margin-left: spacing(2);
      }
    }
    &footerButtonsWithoutTopBorder {
      border-top: none;
      padding-top: 0;
    }
  }
}
