
          @import "./src/styles/_index.scss";
        
@mixin highlightedDay {
  background-color: $colorVioletLightest;
  z-index: 1;
  position: relative;
  color: $colorWhite;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorVioletBase;
    z-index: -1;
    border-radius: $radiusInfinite;
  }
}

@mixin removeHighlightedStyles {
  &::before {
    content: '';
    border-radius: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorWhite;
    z-index: -1;
    border-radius: $radiusInfinite;
  }
}

.prebuiltFilterButtonActive {
  color: $colorVioletBase !important;
  font-weight: $fontWeightSemibold;
  &:focus {
    background-color: transparent !important;
  }
}

.datePickerDetailsContainer {
  background-color: $colorWhite;
  border-radius: $radius8;
}

.dateDropdownItemActive {
  background-color: $colorGrayBase;
}

.dateDropdownItem {
  display: flex;
  justify-content: center;
  text-align: left !important;
}

.prebuiltHeadersContainer {
  border-right: 0.1rem solid $colorGrayLight;
  min-width: 16rem;
}

.datePickerBottomContainer {
  border-top: 0.1rem solid $colorGrayLight;
}

.dropdownContentContainerMonth {
  max-height: 20rem !important;
  overflow: auto;
  width: 12rem !important;
}

.dropdownContentContainerYear {
  max-height: 20rem !important;
  overflow: auto;
  width: 9.4rem !important;
}

.headerButton {
  padding: 0.6rem !important;
  &:hover {
    background-color: $colorGrayBase;
  }
}

.datePicker {
  width: 75rem;
  height: 32rem;
  border: 0;
  font-size: 1.4rem;
  border-radius: 0;
  background-color: $colorWhite;

  display: flex;
  flex-direction: row;
  gap: 5.6rem;

  :global(.react-datepicker__day--in-range),
  :global(.react-datepicker__day--in-selecting-range) {
    background-color: $colorVioletLightest;
    color: $colorBlackWarm;
    border-radius: 0;
    gap: 0;
    margin-left: 0;
    margin-right: 0;

    &:global(.react-datepicker__day--selecting-range-start) {
      @include highlightedDay;
      border-top-left-radius: $radiusInfinite;
      border-bottom-left-radius: $radiusInfinite;
    }

    &:global(.react-datepicker__day--selecting-range-end) {
      @include highlightedDay;
      border-top-right-radius: $radiusInfinite;
      border-bottom-right-radius: $radiusInfinite;
    }
  }

  :global(.hover-range) {
    background-color: $colorVioletLightest;
    color: $colorBlackWarm;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
  }

  :global(.hover-range-active-item) {
    @include highlightedDay;
    background-color: $colorVioletLightest !important;
    margin-left: 0;
    margin-right: 0;
  }

  :global(.react-datepicker__day--disabled) {
    color: $colorCharcoalLight;
  }

  :global(.react-datepicker__current-month) {
    display: none;
    padding: 0%;
  }

  :global(.react-datepicker__day-names) {
    padding: 0;
    width: 100%;
    margin-top: spacing(2);
    display: flex;
    justify-content: space-around;
    color: $colorCharcoalLight;
  }

  :global(.react-datepicker__day-name) {
    color: $colorCharcoalLight;
    min-width: 4.6rem;
  }

  :global(.react-datepicker__day--outside-month) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.6rem;
    width: 4.6rem;
    color: transparent;
    visibility: hidden;
  }
}
