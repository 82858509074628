
          @import "./src/styles/_index.scss";
        
.spinner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  line-height: 0 !important;
  animation: spinner 1s linear infinite;
}

.colorBoard {
  width: 100%;
  height: 100%;
}

.color-- {
  &default {
    background: conic-gradient(from 255deg, #efedfe, $colorVioletDark 75%);
  }
  &auto {
    background: conic-gradient(from 255deg, transparent, currentColor 75%);
  }
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
