
          @import "./src/styles/_index.scss";
        
.root {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-area: 1 / 1 / 2 / 3;
  max-width: 100%;
  margin-left: 0.2rem;

  &.isDisabled {
    opacity: 0.5;
  }

  &.hasError {
    color: $colorRedBase;
  }
}
