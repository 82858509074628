
          @import "./src/styles/_index.scss";
        
body {
  @include fontMainFamily;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.blockScroll {
    overflow: hidden;
  }
}

code {
  @include fontMonospacedFamily;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $baseFontSize;
}

body {
  font-size: 1.6rem;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

strong {
  font-weight: $fontWeightSemibold;
}

.phoneOnly {
  display: none;

  @media #{$phone-only} {
    display: inherit;
  }
}

.tabletUp {
  display: none;

  @media #{$tablet-up} {
    display: inherit;
  }
}

// Storybook styles for the root element
.sb-show-main #root {
  max-width: 100%;
}
