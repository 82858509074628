
          @import "./src/styles/_index.scss";
        
.root {
  display: flex;
  padding-bottom: spacing(3);
  margin-bottom: spacing(6);
  gap: spacing(3);
}

.balanceBox {
  width: 13.1rem;
}
