
          @import "./src/styles/_index.scss";
        
$infiniteWidth: 10000px;

.root {
  width: 100%;
  display: table-row;
  font-size: 1.4rem;
}

.label {
  display: table-cell;
  border-bottom: 1px solid $colorGrayDarker;
  padding: spacing(6, 12, 6, 0);
  white-space: nowrap;
}

.valueCell {
  display: table-cell;
  border-bottom: 1px solid $colorGrayDarker;
  padding: spacing(6, 0);
  width: $infiniteWidth;
}

.copyButton {
  display: inline-flex;
  float: right;
  margin-left: spacing(6);
}

.valueContent {
  overflow-wrap: anywhere;
  overflow: hidden;
}

.root:last-child {
  .label,
  .valueCell {
    border: 0;
  }
}
