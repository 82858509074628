
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
}

.dropdownContent {
  display: none;
  margin-top: spacing(3);
  position: absolute;
  border: 0.1rem solid $colorGrayLight;
  border-radius: $radius8;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: $defaultBoxShadow;
  background-color: $colorWhite;
  z-index: $zindexDropdownMenu;

  &.isFixed {
    position: fixed;
  }

  &.isOpen {
    display: block;
  }

  &.menuWidth--normal {
    width: 16rem;
  }

  &.menuWidth--wide {
    width: 22rem;
  }

  &.menuWidth--wider {
    width: 28rem;
  }
  &.menuWidth--widest {
    width: 34rem;
  }
  &.menuWidth--auto {
    width: auto;
    min-width: 16rem;
  }
}
