
          @import "./src/styles/_index.scss";
        
.sendMoneyForm {
  margin-left: 0 !important;
}

.sendMoneyFormContainer {
  padding-left: 18.4rem;
}
