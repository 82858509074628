:root {
  --rt-color-white: #{$colorWhite} !important;
  --rt-color-dark: #{$colorNavyDarkest} !important;
  --rt-color-success: #{$colorGreenLight} !important;
  --rt-color-error: #{$colorRedBase} !important;
  --rt-color-warning: #{$colorYellowBase} !important;
  --rt-color-info: #{$colorBlueBase} !important;
  --rt-opacity: 1 !important;
  --rt-transition-show-delay: 0.15s !important;
  --rt-transition-closing-delay: 0.15s !important;
}
