
          @import "./src/styles/_index.scss";
        
.outerContainer {
  background-color: $colorGrayLight;
  width: 51rem;
  padding: 1.5rem;
  margin: spacing(4) auto spacing(8) auto;
  border-radius: $defaultRadius;
  min-height: 32rem;
}

.innerContainer {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  border: 0.1rem solid $colorGrayDarker;
  border-radius: $defaultRadius;
  position: relative;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  display: flex;
}
