
          @import "./src/styles/_index.scss";
        
.root {
  border-radius: $radius8;
  color: $colorBlackWarm;
  display: inline-flex;
  align-items: center;
  font-weight: $fontWeightMedium;
  font-size: 1.6rem;
}

.dot {
  margin-right: spacing(2.5);
}

.size-- {
  &small {
    padding: spacing(2, 2.5);
    @include typoLabelExtraSmall;
  }

  &normal {
    padding: spacing(2, 4);
  }
}

:not(.variant--transparent).color-- {
  &orange {
    background-color: $colorOrangeLightest;
  }
  &violet {
    background-color: $colorVioletLightest;
  }
  &yellow {
    background-color: $colorYellowLighter;
  }
  &blue {
    background-color: $colorBlueLightest;
  }
  &lightGray {
    background-color: $colorGrayLight;
  }
  &gray {
    background-color: $colorGrayDarker;
  }
  &red {
    background-color: $colorRedLightest;
    color: $colorRedBase;
  }
  &green {
    background-color: $colorGreenLightest;
    color: $colorGreenBase;
  }
}
