
          @import "./src/styles/_index.scss";
        
.root {
  margin: spacing(2, 0);
  width: 100%;
  flex: 0 0 100%;
  border: 0;
  border-top: 0.1rem solid $colorGrayDarker;

  &.noMargin {
    margin-top: 0;
    margin-bottom: 0;
  }
}
