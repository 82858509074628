
          @import "./src/styles/_index.scss";
        
.root {
  @include contentWrapper;
}

.header {
  margin-top: spacing(4);
  margin-bottom: spacing(2);
}

.icon {
  color: $colorWhite;
  @include flexCenter;
  width: 3.6rem;
  height: 3.6rem;
  margin: spacing(16) auto spacing(4) auto;
  background-color: $colorOrangeBase;
  border-radius: 50%;
}
