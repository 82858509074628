
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
  background-color: $colorGrayLightest;
  border-radius: $radius16 $radius16 0 0;
}

.contentWrapper {
  padding: spacing(6);
}

.transactionTypeIcon {
  background-color: $colorGrayBase;
}

.closeButton {
  position: absolute;
  top: spacing(6);
  right: spacing(6);
  width: 2.4rem;
  height: 2.4rem;
}

.column {
  max-width: 40%;
}

.amount {
  overflow-wrap: break-word;
}
