$rt-namespace: 'Toastify';
$rt-mobile: 'only screen and (max-width : 480px)' !default;

:root {
  --toastify-toast-width: 48rem;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 4.6rem;
  --toastify-toast-max-height: 80rem;
  --toastify-font-family: #{$fontMainFamily};
  --toastify-z-index: #{$zindexNotifications};
  --toastify-spinner-color: $colorCharcoalBase;
  --toastify-spinner-color-empty-area: $colorGrayBase;
}
