
          @import "./src/styles/_index.scss";
        
.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contentCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  @include container;
  position: relative;
  z-index: 1;
}
