
          @import "./src/styles/_index.scss";
        
.hide {
  display: none;
}

.show {
  width: 100%;
}
