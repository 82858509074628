
          @import "./src/styles/_index.scss";
        
.row {
  display: flex;
  align-items: center;

  @media #{$tablet-up} {
    max-width: 80%;
  }
}
