
          @import "./src/styles/_index.scss";
        
.root {
  width: 100%;
  margin: 3rem 0;
  position: relative;
}

.fieldWrapper {
  position: relative;
  background-color: $colorWhite;
  border-radius: $defaultRadius;
  padding: 1.2rem 1.6rem;

  .label {
    top: 1.4rem;
  }
}

.children {
  display: flex;
}

.fieldset {
  position: absolute;
  inset: -0.6rem 0 0;
  pointer-events: none;
  border: 0.1rem solid $colorGrayDarker;
  border-radius: $defaultRadius;
  overflow: hidden;
  min-width: 0;
}

.legend {
  @include typoBodyCopyStandard;
  display: block;
  padding: 0;
  white-space: nowrap;
  max-width: 0.001rem;
  padding: 0;
  margin-left: -0.2rem;
  font-size: 1.2rem;
  height: 1.2rem;
  visibility: hidden;
  overflow: hidden;
  transition: max-width 50ms linear 100ms;
  max-width: 100%;

  span {
    padding: 0 0.5rem;
    display: block;
  }
}

.label {
  @include typoBodyCopyStandard;
  position: absolute;
  left: 1.7rem;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 3.2rem);
  font-size: 1.2rem;
  top: -0.9rem !important;
  color: $colorBlackWarm;
}
