
          @import "./src/styles/_index.scss";
        
.showBorder {
  border-bottom: 0.1rem solid $colorGrayDarker;
}

.content {
  @include container;
  @include contentWrapper;
}
