
          @import "./src/styles/_index.scss";
        
.root {
  margin-bottom: spacing(2.5);
  border: 0.1rem solid $colorGrayDarker;
  border-radius: $radius8;
  background-color: $colorWhite;
  padding: spacing(6);
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: $colorGrayLighter;

    .iconWrapper {
      background-color: $colorGrayDarker;
    }
  }
}

.cellDateAndType {
  display: flex;
  flex: 0 0 auto;
  padding-right: spacing(4);
}

.cellDetails {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: spacing(8);
  padding-right: spacing(4);
}

.subcell {
  overflow: hidden;
}

.cellStatusBadge {
  flex: 0 0 28rem;
  text-align: right;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: $fontWeightSemibold;
  margin-right: spacing(4);

  .month {
    display: block;
    color: $colorCharcoalBase;
    font-size: 1.4rem;
  }

  .day {
    color: $colorNavyDarkest;
    font-size: 1.8rem;
  }
}

.labelAndSublabelCell {
  overflow: hidden;
}

.targetAmountAndAsset {
  color: $colorNavyDarkest;
  font-size: 1.8rem;
  font-weight: $fontWeightSemibold;
  margin-bottom: spacing(1);
}

.senderAndAmount,
.recipient {
  color: $colorCharcoalBase;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.senderAndAmount {
  max-width: 100%;
  display: flex;

  .sender {
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 3rem;
  }
}

.amount {
  overflow-wrap: anywhere;
}
