
          @import "./src/styles/_index.scss";
        
.chevron {
  transform: rotateZ(90deg);
  margin: 0 spacing(2) 0 spacing(1);
  color: $colorCharcoalBase;

  &.hasError {
    color: $colorRedBase;
  }
}
