.#{$rt-namespace}__spinner {
  width: spacing(5);
  height: spacing(5);
  box-sizing: border-box;
  border: 0.2rem solid;
  border-radius: 100%;
  border-color: currentColor;
  border-right-color: transparent;
  animation: #{$rt-namespace}__spin 0.65s linear infinite;
}
