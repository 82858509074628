$fontMainFamily: 'Inter', Helvetica, Arial, sans-serif;

$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemibold: 600;

@mixin fontMainFamily {
  font-family: $fontMainFamily, serif;
}

@mixin fontMonospacedFamily {
  font-family: 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// LEGACY TYPOGRAPHY
// ----------------------------------

@mixin typoBodyEmphasized {
  @include typoBodyCopyStandard;
  font-weight: $fontWeightSemibold;
  color: $colorBlackWarm;
}

@mixin typoBodySmallEmphasized {
  @include typoBodyCopySmall;
  font-weight: $fontWeightSemibold;
  color: $colorBlackWarm;
}

@mixin typoBodyEmphasized {
  @include typoBodyCopyStandard;
  font-weight: $fontWeightSemibold;
  color: $colorBlackWarm;
}

@mixin typoWalletAddress {
  @include fontMonospacedFamily;
  color: $colorBlackWarm;
  font-size: 1.6rem;
  font-weight: $fontWeightRegular;
  line-height: 2.1rem;
  letter-spacing: 0.03rem;
}

@mixin typoWalletAddressSmall {
  @include typoWalletAddress;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

@mixin typoTableHeader {
  @include fontMainFamily;
  color: $colorBlackWarm;
  font-size: 1.3rem;
  font-weight: $fontWeightSemibold;
  line-height: 1.4rem;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}

// TYPOGRAPHY
// ----------------------------------

@mixin commonTypographyProperties {
  @include fontMainFamily;
  font-weight: $fontWeightRegular;
  color: $colorBlackWarm;
}

// Section Headers

@mixin typoSectionHeaderStandard {
  @include commonTypographyProperties;
  font-weight: $fontWeightSemibold;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

@mixin typoSectionHeaderExtraLarge {
  @include typoSectionHeaderStandard;
  font-size: 2.4rem;
  line-height: 3.4rem;
}

@mixin typoSectionHeaderLarge {
  @include typoSectionHeaderStandard;
  font-size: 2rem;
}

@mixin typoSectionHeaderMedium {
  @include typoSectionHeaderStandard;
  font-size: 1.8rem;
}

@mixin typoSectionHeaderSmall {
  @include typoSectionHeaderStandard;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

// Body Copy

@mixin typoBodyCopyStandard {
  @include commonTypographyProperties;
  font-size: 1.6rem;
  line-height: 2.4rem;

  b,
  strong {
    font-weight: $fontWeightSemibold;
  }
}

@mixin typoBodyCopySmall {
  @include typoBodyCopyStandard;
  font-size: 1.4rem;
  line-height: 2.2rem;

  b,
  strong {
    font-weight: $fontWeightSemibold;
  }
}

// Body Link

@mixin typoBodyLinkStandard {
  @include typoBodyCopyStandard;
  color: $colorVioletDark;
  cursor: pointer;
}

@mixin typoBodyLinkSmall {
  @include typoBodyCopySmall;
  color: $colorVioletDark;
  cursor: pointer;
}

// Labels

@mixin typoLabelStandard {
  @include commonTypographyProperties;
  font-size: 1.6rem;
  font-weight: $fontWeightMedium;
  line-height: 2.4rem;
}

@mixin typoLabelSmall {
  @include typoLabelStandard;
  font-size: 1.4rem;
  line-height: 2.2rem;

  b,
  strong {
    font-weight: $fontWeightSemibold;
  }
}

@mixin typoLabelSmallSubLabel {
  @include typoLabelSmall;
  color: $colorCharcoalBase;
}

@mixin typoLabelExtraSmall {
  @include typoLabelSmall;
  font-size: 1.2rem;
  line-height: 2rem;
}

// Sub Copy

@mixin typoSubCopyStandard {
  @include commonTypographyProperties;
  font-size: 1.6rem;
  color: $colorCharcoalBase;
  line-height: 2.4rem;

  b,
  strong {
    font-weight: $fontWeightSemibold;
  }
}

@mixin typoSubCopySmall {
  @include typoSubCopyStandard;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

@mixin typoSubCopyExtraSmall {
  @include typoSubCopyStandard;
  font-size: 1.2rem;
  line-height: 2rem;
}

// Tabs

@mixin typoTabStandard {
  @include commonTypographyProperties;
  color: $colorNavyDarkest;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

@mixin typoTabBolded {
  @include typoTabStandard;
  font-weight: $fontWeightMedium;
}

@mixin typoTabSmall {
  @include typoTabStandard;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

@mixin typoTabBolded {
  @include typoTabSmall;
  font-weight: $fontWeightMedium;
}
