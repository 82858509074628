
          @import "./src/styles/_index.scss";
        
.loaderBar {
  width: 100%;
  height: 0.4rem;
  display: inline-block;
  position: relative;
  background: $colorGrayDark;
  overflow: hidden;
  border-radius: $radiusInfinite;

  &::after {
    content: '';
    width: 6.4rem;
    height: 0.4rem;
    background: $colorVioletBase;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
    border-radius: $radiusInfinite;
  }
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
