
          @import "./src/styles/_index.scss";
        
.root {
  user-select: none;
  padding: spacing(3, 2);

  &.isDisabled {
    opacity: 0.5;
  }

  &.isFocused {
    background-color: $colorGrayLighter;
  }

  &.isSelected {
    background-color: $colorGrayBase;
  }
}

.sublabel {
  margin-top: spacing(2);
  color: $colorCharcoalBase;
  font-size: 1.4rem;
  line-height: 1.2;
}
