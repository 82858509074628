
          @import "./src/styles/_index.scss";
        
.root {
  flex-shrink: 0;
  border-radius: $radiusInfinite;
  background-color: $colorVioletDarker;
  font-weight: $fontWeightMedium;
  color: $colorWhite;
  @include flexCenter;
}

.size-- {
  &regular {
    width: 3.6rem;
    height: 3.6rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  &small {
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}
