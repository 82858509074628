
          @import "./src/styles/_index.scss";
        
.root {
  @include flexCenter;
  display: inline-flex;
  flex-shrink: 0;
  flex-grow: 0;
}

.iconSize-- {
  &16 {
    width: 1.6rem;
    height: 1.6rem;
  }
  &24 {
    width: 2.4rem;
    height: 2.4rem;
  }
  &32 {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.noWhitespace-- {
  &both {
    width: initial;
    height: initial;
  }
  &horizontal {
    width: initial;
    height: 100%;
  }
  &vertical {
    width: 100%;
    height: initial;
  }
}
