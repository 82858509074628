
          @import "./src/styles/_index.scss";
        
@media print {
  .tableHeaderWrapper {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.page {
  position: relative;
  padding: 1cm 1.5cm 0 1.5cm;
  margin: 0 auto;
  width: 21cm;
  height: 27cm;
  line-height: 0.635cm;

  table {
    width: 100%;
    margin-top: 0.31cm;
    th {
      font-weight: $fontWeightMedium;
      text-align: left;
      padding-left: 0.424cm;
      border-bottom: 0.05cm solid $colorGrayLight;
      text-wrap: nowrap;
      font-size: 0.37cm;
    }

    td {
      text-align: right;
      padding-top: 0.31cm;
      padding-bottom: 0.31cm;
      border-bottom: 0.05cm solid $colorGrayLight;
      padding-right: 0.424cm;
      font-size: 0.37cm;
    }
  }
}

.tableHeaderWrapper {
  background-color: $colorGrayLight;
  padding-top: 0.424cm;
  padding-bottom: 0.424cm;
  padding-left: 0.424cm;
  text-align: start;
  margin-top: 0.97cm;
}

.cellMainText {
  font-weight: $fontWeightMedium;
  font-size: 0.37cm;
  line-height: 0.635cm;
}

.cellSecondaryText {
  color: $colorCharcoalBase;
  font-size: 0.37cm;
  line-height: 0.635cm;
}

.receiptHeader {
  font-size: 0.635cm;
  font-weight: $fontWeightMedium;
}

.receiptHeaderContainer {
  border-bottom: 0.05cm solid $colorGrayLight;
}
