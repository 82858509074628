
          @import "./src/styles/_index.scss";
        
.addButton {
  padding: spacing(1, 2);

  span:first-of-type {
    padding-right: spacing(1) !important;
  }
}

.banks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
