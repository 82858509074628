
          @import "./src/styles/_index.scss";
        
.root {
  a {
    color: $colorVioletBase;
    &:hover {
      text-decoration: underline;
    }
  }
}

.variant-- {
  &sectionHeaderStandard {
    @include typoSectionHeaderStandard;
  }
  &sectionHeaderExtraLarge {
    @include typoSectionHeaderExtraLarge;
  }
  &sectionHeaderLarge {
    @include typoSectionHeaderLarge;
  }
  &sectionHeaderMedium {
    @include typoSectionHeaderMedium;
  }
  &sectionHeaderSmall {
    @include typoSectionHeaderSmall;
  }
  &bodyCopyStandard {
    @include typoBodyCopyStandard;
  }
  &bodyCopySmall {
    @include typoBodyCopySmall;
  }
  &bodyLinkStandard {
    @include typoBodyLinkStandard;
  }
  &bodyLinkSmall {
    @include typoBodyLinkSmall;
  }
  &labelStandard {
    @include typoLabelStandard;
  }
  &labelSmall {
    @include typoLabelSmall;
  }
  &labelSmallSubLabel {
    @include typoLabelSmallSubLabel;
  }
  &labelExtraSmall {
    @include typoLabelExtraSmall;
  }
  &subCopyStandard {
    @include typoSubCopyStandard;
  }
  &subCopySmall {
    @include typoSubCopySmall;
  }
  &subCopyExtraSmall {
    @include typoSubCopyExtraSmall;
  }
  &tabStandard {
    @include typoTabStandard;
  }
  &tabBolded {
    @include typoTabBolded;
  }
  &tabSmall {
    @include typoTabSmall;
  }
  &tabBolded {
    @include typoTabBolded;
  }

  // legacy styles
  &legacyBodySmallEmphasized {
    @include typoBodySmallEmphasized;
  }
  &legacyBodyEmphasized {
    @include typoBodyEmphasized;
  }
  &legacyWalletAddress {
    @include typoWalletAddress;
  }
  &legacyWalletAddressSmall {
    @include typoWalletAddressSmall;
  }
}

.align-- {
  &left {
    text-align: left;
  }
  &right {
    text-align: right;
  }
  &center {
    text-align: center;
  }
}

@each $type in ellipsis, clip {
  .overflow--#{$type} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: $type;
  }
}

.overflow--breakWord {
  word-wrap: break-word;
  overflow: hidden;
}
