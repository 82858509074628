
          @import "./src/styles/_index.scss";
        
@keyframes scaleFade {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.root {
  position: relative;
  border-radius: 50%;
  flex: 0 0 auto;
}

.size {
  &--small,
  &--small::before,
  &--small::after {
    width: 0.8rem;
    height: 0.8rem;
  }
  &--normal,
  &--normal::before,
  &--normal::after {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.color {
  &--blue,
  &--blue::before,
  &--blue::after {
    background-color: $colorBlueBase;
  }
  &--violet,
  &--violet::before,
  &--violet::after {
    background-color: $colorVioletBase;
  }
  &--yellow,
  &--yellow::before,
  &--yellow::after {
    background-color: $colorYellowBase;
  }
  &--orange,
  &--orange::before,
  &--orange::after {
    background-color: $colorOrangeBase;
  }
  &--red,
  &--red::before,
  &--red::after {
    background-color: $colorRedBase;
  }
  &--green,
  &--green::before,
  &--green::after {
    background-color: $colorGreenBase;
  }
  &--lightGray,
  &--lightGray::before,
  &--lightGray::after {
    background-color: $colorCharcoalBase;
  }
  &--gray,
  &--gray::before,
  &--gray::after {
    background-color: $colorGrayDarker;
  }
}

.isAnimated {
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    border-radius: 50%;
    animation: scaleFade 2s infinite ease-in-out;
  }

  &::before {
    animation-delay: 0;
  }

  &::after {
    animation-delay: 0.5s;
  }
}
