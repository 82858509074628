
          @import "./src/styles/_index.scss";
        
.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 44rem;
  min-height: 17.6rem;
  border: 0.1rem dashed $colorCharcoalBase;
  border-radius: $defaultRadius;
  padding: spacing(5) spacing(6);
  transition: $animationDefaultTransition;

  &:not(.isDisabled):hover {
    cursor: pointer;
    border-color: $colorBlackWarm;
  }

  &.hasError {
    border-color: $colorRedBase;
  }

  &.isDisabled {
    border-color: $colorGrayDarker;
    pointer-events: none;

    * {
      color: $colorGrayDarker !important;
    }
  }
}

.errorMessage {
  max-width: 100%;
  display: block;
  margin: spacing(2) spacing(4) spacing(4);
}
