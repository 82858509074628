
          @import "./src/styles/_index.scss";
        
.root {
  width: 100%;
  margin: spacing(6) 0;
  position: relative;

  &.isHidden {
    display: none;
  }

  &.isDisabled {
    .input {
      color: $colorGrayDarker;

      & + fieldset {
        border-color: $colorGrayDarker;
        border-width: 0.1rem;
        & + .label {
          color: $colorGrayDarker;
        }
      }
    }

    .supportingTextWrapper {
      color: $colorGrayDarker;
    }
  }
}

.longLabel {
  color: $colorBlackWarm;
  margin-top: spacing(6);

  & + .root {
    margin-top: spacing(4);
  }
}

.fieldWrapper {
  display: flex;
  position: relative;
  background-color: $colorWhite;
  border-radius: $defaultRadius;
  svg {
    margin-right: 0.5rem;
  }
}

.children {
  display: flex;
}

.input {
  @include typoBodyCopyStandard;
  line-height: 1.8rem;
  width: 100%;
  border: 0;
  color: $colorBlackWarm;
  background-color: transparent;

  &:not(.placeholderAlwaysVisible)::placeholder {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &:focus {
    outline: none;
  }

  &.isReadOnly:hover {
    cursor: auto;
  }

  &:not(.isReadOnly, .isDisabled):hover + fieldset {
    border-width: 0.2rem;
  }

  &:not(.isReadOnly):focus,
  &.hasValue {
    &::placeholder {
      opacity: 1;
    }

    & + fieldset {
      .legend {
        max-width: 100%;
      }

      & + .label {
        font-size: 1.2rem;
        top: -0.9rem;
        color: $colorBlackWarm;
      }
    }
  }

  &:not(.isReadOnly):focus {
    & + fieldset {
      border-color: $colorNavyDarkest;
      border-width: 0.2rem;

      & + .label {
        color: $colorNavyDarkest;
      }
    }
  }

  &.hasError {
    & + fieldset {
      border-color: $colorRedBase;
      border-width: 0.2rem;

      & + .label {
        color: $colorRedBase;
      }
    }
  }
}

.fieldset {
  position: absolute;
  inset: -0.6rem 0 0;
  pointer-events: none;
  border: 0.1rem solid $colorCharcoalBase;
  border-radius: $defaultRadius;
  overflow: hidden;
  min-width: 0;
}

.legend {
  @include typoBodyCopyStandard;
  display: block;
  padding: 0;
  white-space: nowrap;
  max-width: 0.001rem;
  padding: 0;
  margin-left: -0.2rem;
  font-size: 1.2rem;
  height: 1.2rem;
  visibility: hidden;
  overflow: hidden;
  transition: max-width 50ms linear 100ms;

  span {
    padding: 0 0.5rem;
    display: block;
  }
}

.label {
  @include typoBodyCopyStandard;
  position: absolute;
  left: 1.7rem;
  line-height: 2rem;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 3.2rem);
  transition: all 200ms ease-in-out;
  transition-property: top, font-size;
  background-color: $colorWhite;
}

.size-- {
  &normal {
    padding: 1.5rem 1.6rem;

    .label {
      top: 1.4rem;
    }
  }

  &small {
    padding: 1.1rem 1.6rem;

    .label {
      top: 1rem;
    }
  }
}

.textarea {
  overflow-y: auto;
  word-wrap: break-word;
  padding: 0;
  width: 100%;
  line-height: 1.3;
  resize: vertical;
}

.supportingTextWrapper {
  max-width: 100%;
  overflow: hidden;
  font-size: 1.2rem;
  color: $colorCharcoalBase;
  padding: 0.8rem 1.6rem 0;
}
