
          @import "./src/styles/_index.scss";
        
.root {
  position: relative;
  z-index: $zindexSidebar;
  flex: 0 0 $sidebarExpandedWidth;
  @include defaultTransition;

  &.isCollapsed {
    flex: 0 0 $sidebarCollapsedWidth;
  }
}

.sidebarWrapper {
  background-color: $colorWhite;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-right: 0.1rem solid $colorGrayDarker;
  top: 0;
  bottom: 0;
  width: 28rem;
  @include defaultTransition;
  padding: 2.4rem;

  .logoWrapper {
    position: relative;
    display: block;
    line-height: 0;
    text-align: center;
  }

  .logoFull {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: spacing(5.5);
    transform: translateX(0);
    opacity: 1;
    @include defaultTransition;
  }

  .logoSign {
    margin-left: auto;
    margin-right: auto;
    transform: translateX(-20rem);
    opacity: 0;
    @include defaultTransition;
  }

  .organizationDropdown button {
    @include defaultTransition(padding);
    padding-left: spacing(5.75) !important;
    padding-top: spacing(3);
    padding-bottom: spacing(3);
  }

  .navigationItems {
    flex-grow: 1;
  }

  .menuItem {
    margin: spacing(4, 0);
    @include defaultTransition(padding);
  }

  .sidebarToggleButtonWrapper {
    display: inline-block;
    @include defaultTransition;

    &:hover {
      cursor: pointer;
    }
  }

  &.isCollapsed:hover {
    box-shadow: 0.8rem 0 0.8rem 0 rgba(0, 0, 0, 0.05);
  }

  &.isCollapsed:not(:hover) {
    width: $sidebarCollapsedWidth;

    .logoFull {
      opacity: 0;
      transform: translateX(-20rem);
    }

    .logoSign {
      opacity: 1;
      transform: translateX(0);
    }

    .organizationDropdown button {
      padding-left: spacing(3.75) !important;
    }

    .menuItem {
      padding-left: spacing(3); // to keep the icon centered
      padding-right: spacing(3);
    }

    .sidebarToggleButtonWrapper {
      margin-right: 0.5rem;
    }
  }
}

// temporary - replace with proper component and style
.sidebarToggleButton {
  border-radius: $radius8 !important;
  padding: spacing(2.25) !important;

  &:hover {
    background-color: $colorGrayBase !important;
  }
}
