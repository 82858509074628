
          @import "./src/styles/_index.scss";
        
.root {
  width: 100%;
  flex: 0 0 100%;
  padding: spacing(4);
  border-width: 0.1rem;
  border-style: solid;
  border-radius: $defaultRadius;
  color: $colorBlackWarm;

  .icon {
    float: left;
    margin-right: spacing(3);

    + .content {
      margin-top: 0.1rem;
    }
  }

  .content {
    overflow: hidden;
  }

  &.variant-- {
    &info {
      border-color: $colorBlueBase;
      background-color: $colorBlueLightest;

      .icon {
        color: $colorBlueBase;
        rotate: 180deg;
      }
    }

    &note {
      border-color: $colorGrayDarker;
      background-color: $colorGrayLight;
      color: $colorBlackWarm;
    }

    &error {
      border-color: $colorRedBase;
      background-color: $colorRedLightest;

      .icon {
        color: $colorRedBase;
      }
    }

    &success {
      border-color: $colorGreenLight;
      background-color: $colorGreenLightest;

      .icon {
        color: $colorGreenLight;
      }
    }

    &warning {
      border-color: $colorYellowBase;
      background-color: $colorYellowLighter;

      .icon {
        color: $colorYellowBase;
      }
    }
  }
}
