
          @import "./src/styles/_index.scss";
        
.counter {
  @include flexCenter;
  @include typoLabelExtraSmall;

  display: inline-flex;
  margin-left: 0;
  height: 2rem;
  min-width: 2rem;
  padding: spacing(0, 1);
  border-radius: 999rem;
  background-color: $colorVioletBase;
  color: $colorWhite;
  font-weight: $fontWeightMedium;

  &.noItems {
    background-color: $colorGrayLight;
    color: $colorCharcoalBase;
    font-weight: $fontWeightRegular;
  }
}

.filtersToggle {
  font-weight: $fontWeightMedium;
  width: 12rem;
}
