$datepicker__background-color: $colorGrayLight;
$datepicker__border-color: $colorCharcoalBase;
$datepicker__highlighted-color: $colorOrangeBase;
$datepicker__holidays-color: $colorVioletBase;
$datepicker__muted-color: $colorGrayDarker;
$datepicker__selected-color: $colorVioletBase;
$datepicker__text-color: $colorBlackWarm;
$datepicker__header-color: $colorBlackWarm;
$datepicker__navigation-disabled-color: $colorGrayBase;

$datepicker__border-radius: $defaultRadius;
$datepicker__day-margin: 0.2rem;
$datepicker__font-size: 1.2rem;
$datepicker__font-family: 'Graphik', helvetica, arial, sans-serif;
$datepicker__item-size: 2.6rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-button-size: 2.8rem;
$datepicker__triangle-size: 8px;
