.#{$rt-namespace}__toast {
  &-theme--dark {
    background: $colorNavyDarkest;
    color: $colorWhite;
  }
  &-theme--light {
    color: $colorBlackWarm;
  }
  &-theme--light#{&}--default {
    border-color: $colorGrayDarker;
    background: $colorGrayLighter;
  }
  &-theme--light#{&}--info {
    background: $colorBlueLightest;
    border-color: $colorBlueBase;
    .#{$rt-namespace}__toast-icon {
      color: $colorBlueBase;
    }
  }
  &-theme--light#{&}--success {
    background: $colorGreenLightest;
    border-color: $colorGreenLight;
    .#{$rt-namespace}__toast-icon {
      color: $colorGreenLight;
    }
  }
  &-theme--light#{&}--warning {
    background: $colorYellowLighter;
    border-color: $colorYellowBase;
    .#{$rt-namespace}__toast-icon {
      color: $colorYellowBase;
    }
  }
  &-theme--light#{&}--error {
    background: $colorRedLightest;
    border-color: $colorRedBase;
    .#{$rt-namespace}__toast-icon {
      color: $colorRedBase;
    }
  }
  &-theme--colored#{&}--default {
    background: $colorCharcoalBase;
    color: $colorGrayLight;
  }
  &-theme--colored#{&}--info {
    color: $colorBlueLightest;
    background: $colorBlueBase;
  }
  &-theme--colored#{&}--success {
    color: $colorGreenLightest;
    background: $colorGreenLight;
  }
  &-theme--colored#{&}--warning {
    color: $colorYellowLighter;
    background: $colorYellowBase;
  }
  &-theme--colored#{&}--error {
    color: $colorRedLightest;
    background: $colorRedBase;
  }
}

.#{$rt-namespace}__progress-bar {
  &-theme--light {
    background: $colorCharcoalBase;
  }
  &-theme--dark {
    background: $colorCharcoalBase;
  }
  &--info {
    background: $colorBlueBase;
  }
  &--success {
    background: $colorGreenLight;
  }
  &--warning {
    background: $colorYellowBase;
  }
  &--error {
    background: $colorRedBase;
  }
  &-theme--colored#{&}--info,
  &-theme--colored#{&}--success,
  &-theme--colored#{&}--warning,
  &-theme--colored#{&}--error {
    background: transparent;
  }
}
