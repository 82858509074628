
          @import "./src/styles/_index.scss";
        
.button {
  @include flexCenter;
  @include fontMainFamily;
  position: relative;
  border: 0.1rem solid transparent;
  border-radius: $radiusInfinite;
  font-size: 1.6rem;
  outline: none;
  font-weight: $fontWeightMedium;

  &:hover {
    cursor: pointer;
  }
}

.iconContainer {
  display: inline-flex;
  align-items: center;

  &.iconPosition--leading {
    &.isNextToContent {
      padding-right: spacing(2.5);
    }
  }

  &.iconPosition--trailing {
    &.isNextToContent {
      padding-left: spacing(2.5);
    }
  }
}

.isCollapsible {
  width: 100%;
  overflow: hidden;
  justify-content: start;

  .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .iconPosition--trailing {
    margin-left: auto;
  }
}

.align-- {
  &center {
    margin-left: auto;
    margin-right: auto;
  }

  &right {
    margin-left: auto;
  }
}

.size-- {
  &normal {
    padding: 1.4rem 2rem;

    &.hasIconOnly {
      padding: 1.5rem;
    }

    @media #{$phone-only} {
      padding: 1.1rem 1.8rem;
    }
  }

  &small {
    padding: 0.9rem 1.6rem;
    font-size: 1.4rem;

    &.hasIconOnly {
      padding: 1.1rem;
    }
  }

  &extraSmall {
    padding: 0.65rem 0.85rem;
    font-size: 1.1rem;

    &.hasIconOnly {
      padding: 1rem;
    }
  }

  &largeIcon {
    padding: spacing(1.5, 3.5, 1.5, 2.5);
  }
}

.variant-- {
  &primary {
    color: $colorWhite;
    background-color: $colorVioletBase;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorVioletDark;
      }
      &:active,
      &.isActive {
        background-color: $colorVioletDarker;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        background-color: $colorGrayLight;
      }
    }

    &.isLoading {
      background-color: $colorGrayLight;
    }
  }

  &secondary {
    color: $colorVioletBase;
    border: 0.1rem solid $colorVioletBase;
    background-color: $colorWhite;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        color: $colorVioletDark;
        border-color: $colorVioletDark;
        background-color: $colorVioletLightest;
      }
      &:active,
      &.isActive {
        color: $colorVioletDarker;
        border-color: $colorVioletDarker;
        background-color: $colorVioletLighter;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        border-color: $colorGrayBase;
      }
    }

    &.isLoading {
      border-color: $colorGrayLight;
      background-color: $colorGrayLight;
    }
  }

  &tertiary {
    color: $colorNavyDarkest;
    border: 0.1rem solid $colorGrayDarker;
    background-color: $colorWhite;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorGrayLight;
      }
      &:active {
        background-color: $colorGrayBase;
      }
      &.isActive {
        .leadingIcon {
          color: $colorVioletBase;
        }
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        border-color: $colorGrayBase;
      }
    }

    &.isLoading {
      border-color: $colorGrayLight;
      background-color: $colorGrayLight;
    }
  }

  &textCta {
    color: $colorNavyDarkest;
    background-color: transparent;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorGrayLight;
      }
      &:active,
      &.isActive {
        background-color: $colorGrayBase;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
      }
    }
  }

  &hyperlink {
    padding: 0;
    color: $colorVioletBase;
    background-color: transparent;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        color: $colorVioletDark;
        text-decoration: underline;
      }
      &:active,
      &.isActive {
        color: $colorVioletDarker;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
      }
    }
  }

  &hyperlinkSecondary {
    padding: 0;
    color: $colorBlackWarm;
    background-color: transparent;
    text-decoration: underline;

    &:not(.isLoading) {
      &:active,
      &.isActive {
        text-decoration: none;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
      }
    }
  }

  &dropdownOpaque {
    padding: 0.55rem 1.2rem;
    color: $colorBlackWarm;
    background-color: $colorGrayLight;
    font-size: 1.4rem;
    border-radius: 999rem;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        background-color: $colorGrayDarker;
      }
      &.isDisabled {
        color: $colorCharcoalBase;
        background-color: $colorGrayLight;
      }
    }
  }

  &dropdownTransparent {
    padding: 0.55rem 0;
    color: $colorBlackWarm;
    font-size: 1.4rem;
    background-color: transparent;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        color: $colorBlackWarm;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
      }
    }
  }

  &destructive {
    color: $colorWhite;
    background-color: $colorRedBase;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorRedDark;
      }
      &:active,
      &.isActive {
        background-color: $colorRedDarkest;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        background-color: $colorGrayLight;
      }
    }

    &.isLoading {
      background-color: $colorGrayLight;
    }
  }

  &destructiveSecondary {
    color: $colorRedBase;
    border-color: $colorRedBase;
    background-color: $colorWhite;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        background-color: $colorRedLightest;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        border-color: $colorGrayBase;
      }
    }

    &.isLoading {
      border-color: $colorGrayLight;
      background-color: $colorGrayLight;
    }
  }

  &navigation {
    padding: 0.35rem 1.2rem;
    color: $colorBlackWarm;
    background-color: transparent;
    font-size: 1.4rem;
    border-radius: 999rem;

    &:not(.isLoading) {
      &:hover,
      &:focus {
        background-color: $colorGrayLight;
      }
      &:active,
      &.isActive {
        color: $colorWhite;
        background-color: $colorNavyDarkest;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
        background-color: transparent;
      }
    }
  }

  &pagination {
    color: $colorBlackWarm;
    border: 0.1rem solid $colorGrayBase;
    background-color: $colorWhite;
    padding: spacing(2, 3);
    font-weight: $fontWeightRegular;
    border-radius: $defaultRadius;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active {
        border-color: $colorCharcoalBase;
      }
      &.isActive {
        border-color: $colorVioletBase;
        background-color: $colorVioletBase;
        color: $colorWhite;
        font-weight: $fontWeightMedium;
      }
      &.isDisabled {
        opacity: 0.3;
      }
    }
  }

  &icon {
    padding: 0 !important;
    color: $colorBlackWarm;
    background-color: transparent;

    &:not(.isLoading) {
      &:hover,
      &:focus,
      &:active,
      &.isActive {
        color: $colorBlackWarm;
      }
      &.isDisabled {
        color: $colorCharcoalLight;
      }
    }
  }
}

.isDisabled,
.isDisabled:hover {
  pointer-events: none;
}

.isLoading {
  cursor: auto !important;
  color: $colorCharcoalBase;

  &:not(.showLabelWhenLoading) {
    .iconContainer,
    .label {
      visibility: hidden;
    }

    .spinner {
      position: absolute;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }
  }

  &.showLabelWhenLoading {
    .spinner {
      margin-right: spacing(2.5);
    }
  }
}
