
          @import "./src/styles/_index.scss";
        
$checkboxSize: 1.8rem;

.root {
  margin: spacing(7) 0;
  flex-basis: 100%;
  display: flex;
  justify-content: left;
  position: relative;

  &:hover {
    .checkbox {
      border-color: $colorBlackWarm;
    }
  }
}

.label {
  display: block;
  @include typoBodyCopyStandard;
  position: relative;
  color: $colorBlackWarm;
  transform: translateY(-0.3rem);
  padding-left: spacing(2);

  a {
    color: $colorVioletBase;
    cursor: pointer;
  }
}

.errorMessage {
  display: block;
  margin-top: 0.8rem;
  padding-left: spacing(2);
}

.checkbox {
  @include flexCenter;
  flex-shrink: 0;
  width: $checkboxSize;
  height: $checkboxSize;
  border: 0.2rem solid $colorCharcoalDarker;
  border-radius: 0.2rem;
}

.checkmark {
  display: none;
}

.input {
  cursor: pointer;
  width: $checkboxSize;
  height: $checkboxSize;
  position: absolute;
  opacity: 0;

  &:checked + .checkbox {
    background-color: $colorVioletBase;
    border-color: $colorVioletBase;

    .checkmark {
      display: block;
    }
  }

  &:focus-visible + .checkbox {
    border-color: $colorVioletDark;
  }
}

:not(.isDisabled) .label {
  cursor: pointer;
}

.isDisabled {
  pointer-events: none;
  cursor: auto;

  .checkbox {
    border-color: $colorGrayDarker !important;
  }

  .input:checked + .checkbox {
    background-color: $colorGrayDarker;
  }

  .label {
    color: $colorGrayDarker;
  }
}

.hasError {
  .checkbox {
    border-color: $colorRedBase !important;
  }
  .input:checked + .checkbox {
    background-color: $colorRedBase;
  }
}
