
          @import "./src/styles/_index.scss";
        
.assetType {
  background: $colorGrayLight;
  padding: spacing(4);
  border-radius: $defaultRadius;
  gap: spacing(1);

  .tokenAndChain {
    display: inline-block;
    position: relative;
    width: spacing(7.5);
    height: spacing(7);

    .networkIcon {
      position: absolute;
      top: spacing(3);
      left: spacing(3);
    }
  }

  .actions {
    display: flex;

    .tooltip,
    > button {
      display: inline;
      margin: spacing(1) spacing(2) 0 0;
    }
  }
}

.isDisabled {
  pointer-events: none;
  opacity: 0.6;
}
