
          @import "./src/styles/_index.scss";
        
.searchWrapper {
  display: flex;
  flex-grow: 1;
}

/*
  Search container
*/
.searchContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  height: 4.8rem;
  border-radius: $radiusInfinite;
}

/*
  Search Suggestion List
*/

.suggestionResultItem {
  cursor: pointer;
  margin: 0;
  padding: spacing(3.5, 0, 2.5, 4.5);

  &:hover {
    background-color: $colorGrayLight;
    border-radius: $radius8;
    .suggestionIcon {
      color: $colorBlackWarm;
    }
  }
}

.suggestionResultItemFocused {
  background-color: $colorGrayLight;
  border-radius: $radius8;
  .suggestionIcon {
    color: $colorBlackWarm;
  }
}

.arrowIcon {
  margin: 0 0.8rem;
}

.suggestionIcon {
  color: $colorCharcoalBase;
  padding: 0;
}

.matchHighlightedPortion {
  font-weight: $fontWeightSemibold;
}

.selectContainer {
  width: 100%;
  height: 4.8rem;
  background-color: $colorWhite;
  border-radius: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: stretch;

  [class$='-control'],
  [class$='-control']:hover {
    border-radius: 2.8rem;
    box-shadow: none;
    border: 0;
    width: 100%;
    margin-left: spacing(1);
    height: 90%;
  }
  [class$='-control']:focus,
  [class$='-control'] > div:focus {
    border-radius: 2.8rem;
  }
  [class$='-menu'] {
    margin-top: -0.1rem;
    width: 100%;
    border-radius: 0px 0px $radius16 $radius16;
    box-shadow: $defaultBoxShadow;
  }

  [class$='-option'],
  [class$='-is-selected'],
  [class$='-option']:focus {
    background-color: $colorWhite;
  }

  [class='search-select__input'] {
    opacity: 1 !important;
    visibility: 'visible' !important;
    min-width: 100% !important;
    min-height: 100% !important;
  }
}

.selectContainerWithResults {
  border-radius: 2.8rem 2.8rem 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background-color: $colorWhite;
}

.searchSuggestionResultList {
  border: 0.1rem solid $colorGrayDarker;
  border-radius: 0 0 $radius16 $radius16;
}

.clearSearchButton {
  color: $colorCharcoalBase;
  :hover {
    color: $colorCharcoalDarker !important;
  }
}

.searchFieldContainer {
  background-color: $colorWhite;
  height: 4.8rem;
  padding-left: spacing(5);
  padding-right: spacing(4);
  border-radius: $radiusInfinite;
  border: 0.1rem solid $colorGrayDarker;
  width: 100%;

  &:has(input:focus),
  &:hover {
    box-shadow: $defaultBoxShadow;
  }
}

.searchFieldContainerWithResults {
  border-radius: 2.8rem 2.8rem 0 0;
  box-shadow: $defaultBoxShadow;
}
