
          @import "./src/styles/_index.scss";
        
.root {
  width: 100%;
  position: relative;
}

.tableWrapper {
  background-image: linear-gradient(to right, $colorWhite, rgba($colorWhite, 0)),
    linear-gradient(to left, $colorWhite, rgba($colorWhite, 0)),
    linear-gradient(to right, rgba($colorBlackWarm, 0.1), rgba($colorBlackWarm, 0)),
    linear-gradient(to left, rgba($colorBlackWarm, 0.1), rgba($colorBlackWarm, 0));
  background-position:
    left center,
    right center,
    left center,
    right center;
  background-repeat: no-repeat;
  background-color: $colorWhite;
  background-size:
    6rem 100%,
    6rem 100%,
    1rem 100%,
    1rem 100%;
  background-attachment: local, local, scroll, scroll;
  margin: 0 auto;
  overflow-x: auto;
}

.table {
  white-space: nowrap;
  border-collapse: collapse;
  border-bottom: 0.1rem solid $colorGrayLight;
  width: 100%;

  tr td,
  tr th {
    border-top: 0.1rem solid $colorGrayLight;
    padding: spacing(4);
    text-align: left;
  }

  tr th {
    @include typoTableHeader;
  }

  tr td {
    @include typoBodyCopySmall;
  }
}

.footer {
  display: flex;
  padding: spacing(4) 0;
  align-items: center;
  justify-content: space-between;
}

.resultsCount {
  color: $colorCharcoalBase;
}

.pagination {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.paginationButton {
  margin-left: spacing(8);
}
