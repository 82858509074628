/*
  Do not import this file to JS/TS directly.
  Instead, use _colorsExportToJS proxy and use colors.ts allowing webpack to tree shake them.
*/

$colorTransparent: transparent;

// Violet
$colorVioletDarkest: #251877;
$colorVioletDarker: #2f1f98;
$colorVioletDark: #3c28c1;
$colorVioletBase: #614de6;
$colorVioletLight: #8274e2;
$colorVioletLighter: #e0dbff;
$colorVioletLightest: #efedfe;

// Orange
$colorOrangeBase: #ec8a40;
$colorOrangeLightest: #f6e7db;

// Navy
$colorNavyDarkest: #1b1543;

// Light Grays
$colorGrayDarker: #dae1ed;
$colorGrayDark: #e4e8f0;
$colorGrayBase: #eaeef5;
$colorGrayLight: #f2f5fa;
$colorGrayLighter: #f7fafe;
$colorGrayLightest: #fbfcfe;
$colorWhite: #ffffff;

// Black and Dark Grays
$colorBlackWarm: #141414;
$colorCharcoalDarker: #605d73;
$colorCharcoalBase: #85829a;
$colorCharcoalLight: #b2b0c1;

// Yellow
$colorYellowBase: #ffc400;
$colorYellowLighter: #fff5d3;
$colorYellowLightest: #fffbec;

// Green
$colorGreenBase: #3b873e;
$colorGreenLight: #6ca83e;
$colorGreenLightest: #e8f5e9;

// Red
$colorRedDarkest: #8e0505;
$colorRedDark: #b52020;
$colorRedBase: #e31b0c;
$colorRedLighter: #ffd6dd;
$colorRedLightest: #feebee;

// Blue
$colorBlueBase: #0b79d0;
$colorBlueLightest: #e3f2fd;
