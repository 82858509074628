
          @import "./src/styles/_index.scss";
        
.root {
  @include fontMainFamily;
  font-weight: $fontWeightSemibold;
  font-size: 1.4rem;
  width: 100%;
  border: none;
  outline: none;
  color: $colorBlackWarm;
  background-color: $colorWhite;
  display: flex;

  &.isDisabled {
    pointer-events: none;

    &:hover {
      cursor: default;
    }
  }

  &:hover:not(.isDisabled),
  &:focus {
    cursor: pointer;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
}

.iconContainer {
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: spacing(2);
}

.checkmarkIcon {
  margin-left: auto;
  align-self: flex-start;
  margin-top: spacing(1.25);
  color: $colorVioletBase;
}

.label {
  line-height: 2.2rem;
  margin-right: spacing(2.5);
  text-align: left;
}

.hasIcon {
  .contentWrapper {
    align-items: start;
  }
  .label {
    margin-top: spacing(0.25);
  }
  .checkmarkIcon {
    margin-top: spacing(1.5);
  }
}

.isCta {
  .label,
  .iconContainer {
    color: $colorVioletBase !important;
    @include typoLabelStandard;
  }
}

.variant-- {
  &dropdownLegacy {
    padding: spacing(2, 4);

    &:hover:not(.isDisabled),
    &:focus {
      background-color: $colorGrayLight;
    }
    &:active {
      background-color: $colorGrayDarker;
    }

    &.isDisabled .contentWrapper {
      opacity: 0.5;
    }
  }
  &dropdown {
    padding: spacing(4);
    @include typoBodyCopyStandard;

    .label,
    .iconContainer {
      color: $colorBlackWarm;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $colorGrayLighter;
    }

    &.isActive {
      .label {
        font-weight: $fontWeightMedium;
      }
    }

    &.isDisabled .contentWrapper {
      opacity: 0.5;
    }
  }
  &navPrimary {
    border-radius: $radiusInfinite;
    padding: spacing(3, 5);
    color: $colorCharcoalDarker;
    @include typoLabelStandard;

    .contentWrapper {
      flex-shrink: 1;
    }

    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .iconContainer {
      color: $colorCharcoalBase;
    }

    &:hover:not(.isDisabled) {
      background-color: $colorGrayLighter;
      color: $colorBlackWarm;

      .iconContainer {
        color: $colorBlackWarm;
      }
    }

    &:active,
    &:focus,
    &.isActive,
    &.isActive .iconContainer {
      color: $colorVioletBase !important;

      .iconContainer {
        color: $colorVioletBase !important;
      }
    }

    &.isDisabled {
      .iconContainer,
      .label {
        color: $colorCharcoalLight !important;
      }
    }
  }
}
