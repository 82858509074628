@import './variables.scss';
@import './vendor-original';

@mixin highlightedDay {
  background-color: $colorVioletLightest;
  z-index: 1;
  position: relative;
  color: $colorWhite;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorVioletBase;
    z-index: -1;
    border-radius: $radiusInfinite;
  }
}

@mixin removeHighlightedStyles {
  &::before {
    content: '';
    border-radius: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorWhite;
    z-index: -1;
    border-radius: $radiusInfinite;
  }
}

:global(.react-datepicker__header) {
  width: 100%;
  background-color: $colorWhite;
  border: 0;
}

:global(.react-datepicker__month-container) {
  width: 100%;
  min-height: 100%;
  margin-top: 0;
}

:global(.react-datepicker__month) {
  margin-top: 1.4rem !important;
}

:global(.react-datepicker__week) {
  width: 100%;
  background-color: $colorWhite;
  display: flex;
  flex-direction: row;
  margin-top: 0.3rem;
}

:global(.react-datepicker__day-names) {
  margin-top: 1.6rem !important;
}

:global(.react-datepicker__day--in-range),
:global(.react-datepicker__day--in-selecting-range) {
  background-color: $colorVioletLightest;
  color: $colorBlackWarm;
  border-radius: 0;
  gap: 0;
  margin-left: 0;
  margin-right: 0;

  &:global(.react-datepicker__day--selecting-range-start) {
    @include highlightedDay;
    border-top-left-radius: $radiusInfinite;
    border-bottom-left-radius: $radiusInfinite;
  }

  &:global(.react-datepicker__day--selecting-range-end) {
    @include highlightedDay;
    border-top-right-radius: $radiusInfinite;
    border-bottom-right-radius: $radiusInfinite;
  }
}

:not(:global(.react-datepicker__day--outside-month)) {
  &:global(.react-datepicker__day) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.6rem;
    width: 4rem;
    margin-left: 0;
    margin-right: 0;

    &:global(.react-datepicker__day--range-start),
    &:global(.react-datepicker__day--range-end) {
      @include highlightedDay;
    }

    &:global(.react-datepicker__day--range-start) {
      border-top-left-radius: $radiusInfinite;
      border-bottom-left-radius: $radiusInfinite;
      font-weight: $fontWeightRegular !important;
    }

    &:global(.react-datepicker__day--range-end) {
      border-top-right-radius: $radiusInfinite;
      border-bottom-right-radius: $radiusInfinite;
      font-weight: $fontWeightRegular !important;
    }

    &:hover:not(:global(.react-datepicker__day--disabled)):not(
        :global(.react-datepicker__day--range-start)
      ):not(:global(.react-datepicker__day--range-end)):not(
        :global(.react-datepicker__day--selecting-range-end)
      ) {
      &:global(.react-datepicker__day--in-range) {
        @include highlightedDay;
        border-radius: 0 !important;
      }

      &:global(.hover-range-active-item-decrease) {
        background-color: $colorVioletLightest !important;
        margin-left: 0;
        margin-right: 0;

        border-top-right-radius: $radiusInfinite !important;
        border-bottom-right-radius: $radiusInfinite !important;
      }

      background-color: $colorVioletBase;
      border-radius: $radiusInfinite;
      color: $colorWhite !important;
      font-weight: $fontWeightRegular;
    }

    &:global(.hover-range-previous-selected-end-date-empty) {
      @include removeHighlightedStyles;
      z-index: 1;
      position: relative;
      color: $colorBlackWarm;
    }

    &:global(.hover-range-connection-left) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: $radiusInfinite !important;
      border-bottom-right-radius: $radiusInfinite !important;
    }

    &:global(.hover-range-connection-right) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:global(.hover-range-previous-selected-end-date),
    &:global(.hover-range-previous-selected-start-date) {
      background-color: $colorVioletLightest !important;
      z-index: 1;
      position: relative;
      color: $colorBlackWarm;
      &::before {
        content: '';
        border-radius: 0 !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $colorVioletLightest;
        z-index: -1;
        border-radius: $radiusInfinite;
      }
    }

    &:global(.hover-range-empty) {
      @include removeHighlightedStyles;
      background-color: $colorWhite !important;
      color: $colorBlackWarm;
      border-radius: 0;
      gap: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
