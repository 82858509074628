
          @import "./src/styles/_index.scss";
        
.resultsCount {
  margin-right: auto;
}

.pagination {
  display: flex;
  align-items: center;
  margin-left: spacing(8);
}

.paginationButton {
  margin-left: spacing(2);
}

.resultsPerPage {
  display: flex;
  align-items: center;
  margin-left: spacing(8);
  font-size: 1.4rem;

  .selectField {
    margin: spacing(0, 0, 0, 2);
  }
}
