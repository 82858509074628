
          @import "./src/styles/_index.scss";
        
.checkboxDropdownButtonLabel {
  font-weight: $fontWeightMedium;
}

.checkboxField {
  margin: spacing(2, 0, 0, 0) !important;
  padding: spacing(2);
}

.filterDropdownContentContainer {
  width: 30rem !important;
  padding: spacing(4) !important;
  border: 0.1rem solid $colorGrayDarker;
  box-shadow: $defaultBoxShadow;
}

.counter {
  @include flexCenter;
  @include typoLabelExtraSmall;

  display: inline-flex;
  margin-left: 0;
  height: 2rem;
  min-width: 2rem;
  padding: spacing(0, 1);
  border-radius: $radiusInfinite;
  background-color: $colorVioletBase;
  color: $colorWhite;
  font-weight: $fontWeightMedium;

  &.noItems {
    background-color: $colorGrayLight;
    color: $colorCharcoalBase;
    font-weight: $fontWeightRegular;
  }
}
