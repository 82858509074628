
          @import "./src/styles/_index.scss";
        
.root {
  width: 100%;
}

.wrapper {
  @include container;
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.isFullWidth {
  .wrapper {
    @include contentWrapper;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.variant-- {
  &primary,
  &secondary {
    border-bottom: 0.1rem solid $colorGrayDarker;
  }
  &picker {
    background: $colorGrayLight;
    border-radius: $defaultRadius;
    padding: spacing(1);
    gap: spacing(1);
    overflow-x: hidden;
  }
}
